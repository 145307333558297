<template>
  <div>
    <div>
          <h2 class="mb-3 text-2xl">Immobilienbewertung in 8 simplen Schritten</h2>
          <p>
            Einfach die zutreffenden Optionen auswählen, absenden und schon macht sich das Ergebnis inklusive wertvoller Zusatzinformationen rund um den Markt- und Verkehrswert Ihrer Immobilie auf den Weg zu Ihnen. Schnell, realistisch und sicher.
          </p>
        </div>
    <div class="py-4 main md:py-8">
      <div class="flex flex-wrap justify-center select">
        <div
          class="mx-3"
          @click="handleApartment">
          <icon hover="true" :src="`${fileadminPath}/assets/icons/wohnung/20200629_vonheide_wohnung.svg`" title="Wohnung" />
        </div>
        <div
          class="mx-3"
          @click="NEXT()">
          <icon hover="true" :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_einfamilienhaus.svg`" title="Haus" />
        </div>
        <div
          class="mx-3"
          @click="handleMultiFamilyHouse">
          <icon hover="true" :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_mehrfamilienhaus.svg`" title="Mehrfamilienhaus" />
        </div>
        <div
          class="mx-3"
          @click="handleVilla">
          <icon hover="true" :src="`${fileadminPath}/assets/icons/20200629_vonheide_villa.svg`" title="Villa" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../Icon.vue';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'Step1',
  components: {
    Icon
  },
  computed: {
    ...mapGetters(['fileadminPath'])
  },
  methods: {
    ...mapMutations(['SET_STATE', 'NEXT', 'GO_TO_STEP', 'SET_VILLA']),
    handleApartment () {
      this.SET_STATE({
        key: 'realEstateType',
        value: 'APARTMENT',
        goNext: true
      });
    },
    handleVilla () {
      const defaultData = {
        exposureView: 'VERY_ATTRACTIVE',
        residentialEnvironment: 'VERY_CALM',
        amountOfSunlight: 'VERY_BRIGHT',
        roomLayout: 'VERY_SPACIOUS'
      };
      this.SET_STATE({
        key: 'realEstateType',
        value: 'SINGLE_FAMILY_HOUSE',
        goNext: false
      });
      this.SET_STATE({
        key: 'exposure',
        value: defaultData,
        goNext: false
      });
      this.SET_VILLA(true);
      this.GO_TO_STEP(2);
    },
    handleMultiFamilyHouse () {
      this.SET_STATE({
        key: 'facilities',
        value: {
          wallCovering: 'AVERAGE',
          wallCoveringQuality: 'WELL_KEPT',
          roof: 'AVERAGE',
          roofQuality: 'WELL_KEPT',
          insulation: 'AVERAGE',
          insulationQuality: 'WELL_KEPT',
          floorCovering: 'AVERAGE',
          floorCoveringQuality: 'WELL_KEPT',
          window: 'AVERAGE',
          windowQuality: 'WELL_KEPT',
          heating: 'AVERAGE',
          heatingQuality: 'WELL_KEPT',
          sanitaryInstallation: 'AVERAGE',
          sanitaryInstallationQuality: 'WELL_KEPT'
        },
        goNext: false
      });
      this.SET_STATE({
        key: 'realEstateType',
        value: 'MFH',
        goNext: false
      });
      this.GO_TO_STEP(2);
    }
  }
};
</script>
