<template>
  <button @click="$emit('click', title)" class="select-item" :class="{'pointer-events-none' : !hover}">
    <img :src="src" @mouseover="switchSrc" @mouseout="switchSrc" />
    <div class="text-center" v-if="title">{{ title }}</div>
  </button>
</template>

<script>
export default {
  name: 'Icon',
  props: ['src', 'title', 'hover'],
  methods: {
    switchSrc (e) {
      if (!this.hover) return;
      const el = e.target;
      if (e.type === 'mouseover') {
        el.src = el.src.replace('.svg', '_hover.svg');
      } else if (e.type === 'mouseout') {
        el.src = el.src.replace('_hover.svg', '.svg');
      }
    }
  }
};
</script>
