<template>
  <nav>
      <div v-for="(step, index) in maxSteps" :key="index" :class="{'active' : index === currentStep}"></div>
    </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Progress',
  computed: {
    ...mapState(['currentStep', 'maxSteps'])
  }
};
</script>
