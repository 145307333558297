<template>
  <div class="bg-blue" id="widget">
    <div
      class="container relative px-3 py-8 mx-auto mb-2 text-center text-white md:mb-8 md:px-10 md:py-8"
    >
    <!-- <pre class="text-left">Villa? {{ isVilla }}</pre>
    <pre class="text-left"><code>{{ apiData }}</code></pre> -->
      <transition name="component-fade" mode="out-in">
        <step-0 v-if="currentStep === 0" />
        <step-1 v-if="currentStep === 1" />
        <step-2 v-if="currentStep === 2" />
        <step-3 v-if="currentStep === 3" />
        <step-final v-if="currentStep === 4" />
        <step-results v-if="currentStep === 5" />
      </transition>

      <Progress v-if="currentStep !== 5"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Step0 from './steps/step0.vue';
import Step1 from './steps/step1.vue';
import Step2 from './steps/step2.vue';
import Step3 from './steps/step3.vue';
import StepFinal from './steps/final.vue';
import StepResults from './steps/results.vue';

import Progress from './Progress.vue';

export default {
  name: 'FormContainer',
  components: {
    Progress,
    Step0,
    Step1,
    Step2,
    Step3,
    StepFinal,
    StepResults
  },
  computed: {
    ...mapState(['currentStep']),
    ...mapState(['apiData']),
    ...mapState(['isVilla'])
  },
  methods: {
    ...mapMutations(['NEXT', 'PREV'])
  }
};
</script>
