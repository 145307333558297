var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.loader.isLoading,"background-color":_vm.loader.background,"color":_vm.loader.color,"opacity":_vm.loader.opacity,"is-full-page":_vm.loader.fullPage,"loader":_vm.loader.loader},on:{"update:active":function($event){return _vm.$set(_vm.loader, "isLoading", $event)}},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('div',{staticClass:"block mt-3 text-pulse"},[_vm._v("\n        Wert wird ermittelt...\n      ")])]},proxy:true}])}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"py-4 main md:py-8"},[_c('div',{staticClass:"flex flex-wrap justify-center space-x-6 select"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"text-left text-gray-100",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"flex flex-wrap justify-around -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-3 md:mb-0 md:w-1/3"},[_c('label',{staticClass:"relative block"},[_c('span',{staticClass:"text-gray-200"},[_vm._v("Name")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Name","rules":"min:2|alpha_spaces|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.client.name),expression:"client.name"}],staticClass:"block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange",attrs:{"type":"text","placeholder":"Ihr Name..."},domProps:{"value":(_vm.client.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.client, "name", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('form-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"w-full px-3 mb-3 md:mb-0 md:w-1/3"},[_c('label',{staticClass:"relative block"},[_c('span',{staticClass:"text-gray-200"},[_vm._v("E-Mail")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"E-Mail","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.client.email),expression:"client.email"}],staticClass:"block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange",attrs:{"type":"email","placeholder":"Ihre E-Mailadresse..."},domProps:{"value":(_vm.client.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.client, "email", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('form-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"w-full px-3 mb-3 md:mb-6 md:w-1/3"},[_c('label',{staticClass:"relative block"},[_c('span',{staticClass:"text-gray-200"},[_vm._v("Telefon")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Telefon","rules":"min:5|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.client.phone),expression:"client.phone"}],staticClass:"block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange",attrs:{"type":"text","placeholder":"Ihre Telefonummer"},domProps:{"value":(_vm.client.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.client, "phone", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('form-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"w-full px-3 mb-3 md:mb-6"},[_c('label',{staticClass:"relative block"},[_c('span',{staticClass:"text-gray-200"},[_vm._v("Warum möchten Sie Ihre Immobilie verkaufen")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.client.message),expression:"client.message"}],staticClass:"block w-full mt-1 bg-gray-700 form-textarea focus:text-gray-900 border-orange focus:bg-gray-500 focus:border-orange",attrs:{"rows":"4","placeholder":"..."},domProps:{"value":(_vm.client.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.client, "message", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"w-full px-3 text-center"},[_c('button',{staticClass:"text-gray-800 btn bg-orange hover:bg-yellow-600",attrs:{"type":"submit","disabled":invalid}},[_vm._v("\n                Absenden und Wert ermitteln\n              ")]),_vm._v(" "),(_vm.apiError)?_c('span',{staticClass:"relative block w-48 px-2 py-2 mx-auto mt-2 text-sm leading-tight text-red-100 bg-red-600 rounded-sm shadow-lg"},[_vm._v(_vm._s(_vm.errorMsg))]):_vm._e()])])])]}}])})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-3 h1"},[_vm._v("Kontaktinfo")]),_vm._v(" "),_c('p',[_vm._v("\n      Bitte geben Sie Ihre Kontaktmöglichkeiten an damit wir uns an Sie wenden können.\n    ")])])}]

export { render, staticRenderFns }