<template>
  <div>
    <div>
      <h2 class="mb-3 h1">Immobilien Info</h2>
    </div>
    <div class="py-4 main md:py-8">
      <div class="flex flex-wrap justify-center space-x-6 select">
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(onSubmit)" class="text-left text-gray-100">
            <div class="flex flex-wrap -mx-3">
              <div class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
                <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_wohnflaeche.svg`" />
                <label class="relative block w-full -mt-4 ">
                  <span class="text-gray-200">Wohnfläche (m2)</span>
                  <validation-provider name="Wohnfläche" rules="numeric|required" v-slot="{ errors }">
                    <input v-model="livingArea" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="z.B. 200" inputmode="numeric" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>

              <div v-if="apiData.realEstateType !== 'APARTMENT'" class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
                <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_flaeche.svg`" />
                <label class="relative block w-full -mt-4 ">
                  <span class="text-gray-200">Grundstück (m2)</span>
                  <validation-provider name="Grundstück" rules="numeric|required" v-slot="{ errors }">
                    <input v-model="siteArea" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="z.B. 200" inputmode="numeric" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>

              <div v-if="!apiData.penthouse && !apiData.floor && apiData.realEstateType === 'APARTMENT'" class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
                <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_flaeche.svg`" />
                <label class="relative block w-full -mt-4 ">
                  <span class="text-gray-200">Etage</span>
                  <validation-provider name="Zimmeranzahl" rules="required" v-slot="{ errors }">
                    <select required v-model="floor" class="block w-full mt-1 bg-gray-700 form-select focus:text-gray-900 border-orange focus:bg-gray-500 focus:border-orange">
                      <option v-for="option in options.floor" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>

              <div class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
                <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_baujahr.svg`" />
                <label class="relative block w-full -mt-4 ">
                  <span class="text-gray-200">Baujahr</span>
                  <validation-provider name="Baujahr" rules="digits:4|min_value:1500|required" v-slot="{ errors }">
                    <input v-model="constructionYear" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="z.B. 2012" inputmode="numeric" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>

              <div v-if="apiData.realEstateType !== 'MFH'" class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
                <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_zimmer.svg`" />
                <label class="relative block w-full -mt-4 ">
                  <span class="text-gray-200">Zimmer</span>
                  <validation-provider name="Zimmeranzahl" rules="required" v-slot="{ errors }">
                    <select required v-model="roomCount" class="block w-full mt-1 bg-gray-700 form-select focus:text-gray-900 border-orange focus:bg-gray-500 focus:border-orange">
                      <option v-for="option in options.roomCount" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>

              <div v-if="apiData.realEstateType == 'MFH'" class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
                <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/20201211_vonheide_wohneinheit.svg`" />
                <label class="relative block w-full -mt-4 ">
                  <span class="text-gray-200">Anzahl Wohneinheiten</span>
                  <validation-provider name="Grundstück" rules="numeric|required" v-slot="{ errors }">
                    <input v-model="livingQuarters" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="z.B. 6" inputmode="numeric" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>

               <div class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
                <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/20210128_vonheide_parken.svg`" />
                <label class="relative block w-full -mt-4 ">
                  <span class="text-gray-200">Parken</span>
                  <validation-provider name="Parken" v-slot="{ errors }">
                    <select v-model="parking" class="block w-full mt-1 bg-gray-700 form-select focus:text-gray-900 border-orange focus:bg-gray-500 focus:border-orange">
                      <option v-for="option in options.parking" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>

              <villa-facilities v-if="isVilla" @change="onVillaFacilityChange" />
              <villa-special-features v-if="isVilla" @features-change="onVillaFeaturesChange" />

              <div class="w-full px-3 my-3 mb-6 text-center md:px-8">
                <label class="relative inline-flex items-center ">
                  <input v-model="rented" type="checkbox" class="form-checkbox checkbox--orange" />
                  <span class="ml-2">Ist die Immobilie vermietet?</span>
                </label>
                <label class="relative inline-flex items-center ml-5">
                  <input v-model="cellar" type="checkbox" class="form-checkbox checkbox--orange" />
                  <span class="ml-2">Hat die Immobilie einen Keller?</span>
                </label>
              </div>

              <div class="w-full px-3 text-center md:px-8">
                <button class="text-gray-800 btn bg-orange hover:bg-yellow-600" type="submit" :disabled="invalid" :title="[invalid ? 'Alle Felder müssen gültig sein.' : 'weiter']">
                  Weiter
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import Icon from '../Icon.vue';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, numeric, digits, min_value } from 'vee-validate/dist/rules';
import FormError from '../FormError.vue';
import VillaFacilities from '../VillaFacilities.vue';
import VillaSpecialFeatures from '../VillaSpecialFeatures.vue';

import de from 'vee-validate/dist/locale/de.json';

localize('de', de);
extend('required', required);
extend('digits', digits);
extend('numeric', numeric);
extend('min_value', min_value);

export default {
  name: 'Step3',
  components: {
    Icon,
    ValidationProvider,
    ValidationObserver,
    FormError,
    VillaFacilities,
    VillaSpecialFeatures
  },
  data: () => ({
    options: {
      floor: [
        { text: 'Erdgeschoss', value: 'GROUND_FLOOR' },
        { text: '1. Stockwerk', value: 'FLOOR_1' },
        { text: '2. Stockwerk', value: 'FLOOR_2' },
        { text: '3. Stockwerk', value: 'FLOOR_3' },
        { text: '4. Stockwerk', value: 'FLOOR_4' },
        { text: '5. Stockwerk', value: 'FLOOR_5' },
        { text: '6. Stockwerk o. höher', value: 'FLOOR_6' },
        { text: 'Souterrain/Untergeschoss', value: 'BASEMENT' }
      ],
      roomCount: [
        { value: 'ONE', text: '1' },
        { value: 'ONE_HALF', text: '1,5' },
        { value: 'TWO', text: '2' },
        { value: 'TWO_HALF', text: '2,5' },
        { value: 'THREE', text: '3' },
        { value: 'THREE_HALF', text: '3,5' },
        { value: 'FOUR', text: '4' },
        { value: 'FIVE', text: '5' },
        { value: 'SIX_OR_MORE', text: '6 oder mehr' }
      ],
      parking: [
        { value: '', text: 'Nicht verfügbar' },
        { value: 'parkingSpace', text: 'Im Freien' },
        { value: 'basementGarage', text: 'Tiefgarage' },
        { value: 'singleGarage', text: 'Einzelgarage' },
        { value: 'doubleGarage', text: 'Doppelgarage' },
        { value: 'duplex', text: 'Duplex' },
        { value: 'carport', text: 'Carport' }
      ]
    },
    constructionYear: '',
    floor: null,
    siteArea: '',
    roomCount: '',
    livingArea: '',
    rented: '',
    livingQuarters: '',
    cellar: false,
    parking: '',
    specialFeatures: null
  }),
  computed: {
    ...mapState(['apiData', 'isVilla']),
    ...mapGetters(['fileadminPath'])
  },
  mounted () {},
  methods: {
    ...mapMutations(['SET_STATE', 'NEXT', 'SET_VILLA_FACILITY']),
    onVillaFacilityChange ({ name, value }) {
      this.SET_VILLA_FACILITY({ key: name, value });
    },
    onVillaFeaturesChange (newSpecialFeatures) {
      this.specialFeatures = newSpecialFeatures;
    },
    onSubmit () {
      if (this.constructionYear) this.SET_STATE({ key: 'constructionYear', value: this.constructionYear });
      if (this.floor || this.apiData.floor) this.SET_STATE({ key: 'floor', value: this.floor || this.apiData.floor });
      if (this.siteArea) this.SET_STATE({ key: 'siteArea', value: this.siteArea });
      if (this.roomCount || this.apiData.roomCount) this.SET_STATE({ key: 'roomCount', value: this.roomCount || this.apiData.roomCount });
      if (this.livingArea) this.SET_STATE({ key: 'livingArea', value: this.livingArea });
      if (this.rented !== '') this.SET_STATE({ key: 'rented', value: this.rented });
      if (this.livingQuarters) this.SET_STATE({ key: 'livingQuarters', value: this.livingQuarters });
      this.SET_STATE({ key: 'cellar', value: this.cellar });
      if (this.parking !== '') this.SET_STATE({ key: 'parking', value: { [this.parking]: 1 } });
      if (this.isVilla && this.specialFeatures) this.SET_STATE({ key: 'specialFeatures', value: this.specialFeatures });

      setTimeout(() => {
        this.NEXT();
      }, 100);
    }
  }
};
</script>
