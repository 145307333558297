<template>
  <div>
    <div v-if="apiData.realEstateType == 'MFH'">
      <h2 class="mb-3 h1">Herzlichen Dank für Ihre Anfrage zur Wertermittlung!</h2>
      <p>
       Im nächsten Schritt werden Ihre Angaben von uns ausgewertet. Das Ergebnis senden wir Ihnen via E-Mail an <span class="font-bold text-orange">{{ apiData.client.email }}</span> zu.
      </p>
    </div>
    <div v-else>
      <h2 class="mb-3 h1">Immobilienwert ermittelt!</h2>
      <p>
        Wir konnten Ihren Immobilienwert erfolgreich ermitteln.
      <br>
      Eine PDF Datei mit allen Informationen wurde an <span class="font-bold text-orange">{{ apiData.client.email }}</span> gesendet.</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'StepResults',
  mounted () {
    // console.log(this.apiData);
  },
  computed: {
    ...mapState(['apiData'])
  }
};
</script>
