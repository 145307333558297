<template>
  <div>
    <div v-if="apiData.realEstateType === 'APARTMENT'">
      <h2 class="mb-3 h1">Wohnungstyp</h2>
      <p>
        Bitte wählen Sie den Eigentumswohnungtyp aus.
      </p>
    </div>
    <div v-else>
      <h2 class="mb-3 h1">Haustyp</h2>
      <p>
        Bitte wählen Sie Ihren Haustyp aus.
      </p>
    </div>
    <div class="py-4 main md:py-8">
      <div v-if="apiData.realEstateType === 'APARTMENT'" class="flex flex-wrap justify-center select">
        <div
          class="mx-3"
          @click="NEXT()"
        >
          <icon
            hover="true"
            :src="`${fileadminPath}/assets/icons/wohnung/20200629_vonheide_wohnung.svg`"
            title="Etagenwohnung"
          />
        </div>
        <div
          class="mx-3"
          @click="
            SET_STATE({
              key: 'floor',
              value: 'GROUND',
              goNext: true
            })
          "
        >
          <icon
            hover="true"
            :src="`${fileadminPath}/assets/icons/20200629_vonheide_eg.svg`"
            title="Erdgeschosswohnung"
          />
        </div>
        <div
          class="mx-3"
          @click="
            SET_STATE({
              key: 'penthouse',
              value: true,
              goNext: true
            })
          "
        >
          <icon
            hover="true"
            :src="`${fileadminPath}/assets/icons/20200629_vonheide_penthouse.svg`"
            title="Penthouse"
          />
        </div>
        <div
          class="mx-3"
          @click="
            SET_STATE({
              key: 'floor',
              value: 'BASEMENT',
              goNext: true
            })
          "
        >
          <icon
            hover="true"
            :src="`${fileadminPath}/assets/icons/20200629_vonheide_maisonette.svg`"
            title="Maisonette"
          />
        </div>
      </div>
      <div v-else class="flex flex-wrap justify-center select">
        <div
          class="mx-3"
          @click="
            SET_STATE({
              key: 'realEstateType',
              value: 'SINGLE_FAMILY_HOUSE',
              goNext: true
            })
          "
        >
          <icon
            hover="true"
            :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_einfamilienhaus.svg`"
            title="Einfamilienhaus"
          />
        </div>
        <div
          class="mx-3"
          @click="
            SET_STATE({
              key: 'realEstateType',
              value: 'SEMIDETACHED_HOUSE',
              goNext: true
            })
          "
        >
          <icon
            hover="true"
            :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_doppelhaushaelfte.svg`"
            title="Doppelhaushälfte"
          />
        </div>
        <div
          class="mx-3"
          @click="
            SET_STATE({
              key: 'realEstateType',
              value: 'MID_TERRACE_HOUSE',
              goNext: true
            })
          "
        >
          <icon
            hover="true"
            :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_reihenhaus.svg`"
            title="Reihenmittelhaus"
          />
        </div>
        <div
          class="mx-3"
          @click="
            SET_STATE({
              key: 'realEstateType',
              value: 'END_TERRACE_HOUSE',
              goNext: true
            })
          "
        >
          <icon
            hover="true"
            :src="`${fileadminPath}/assets/icons/haus/20200629_vonheide_haus_reihenhaus.svg`"
            title="Reihenendhaus"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../Icon.vue';
import { mapMutations, mapGetters, mapState } from 'vuex';

export default {
  name: 'Step1',
  components: {
    Icon
  },
  computed: {
    ...mapState(['apiData']),
    ...mapGetters(['fileadminPath'])
  },
  methods: {
    ...mapMutations(['SET_STATE', 'NEXT'])
  }
};
</script>
