<template>
  <div>
    <loading
      :active.sync="loader.isLoading"
      :background-color="loader.background"
      :color="loader.color"
      :opacity="loader.opacity"
      :is-full-page="loader.fullPage"
      :loader="loader.loader"
    >
      <template v-slot:after>
        <div class="block mt-3 text-pulse">
          Wert wird ermittelt...
        </div>
      </template>
    </loading>

    <div>
      <h2 class="mb-3 h1">Kontaktinfo</h2>
      <p>
        Bitte geben Sie Ihre Kontaktmöglichkeiten an damit wir uns an Sie wenden können.
      </p>
    </div>
    <div class="py-4 main md:py-8">
      <div class="flex flex-wrap justify-center space-x-6 select">
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(onSubmit)" class="text-left text-gray-100">
            <div class="flex flex-wrap justify-around -mx-3">
              <div class="w-full px-3 mb-3 md:mb-0 md:w-1/3">
                <label class="relative block">
                  <span class="text-gray-200">Name</span>
                  <validation-provider name="Name" rules="min:2|alpha_spaces|required" v-slot="{ errors }">
                    <input v-model="client.name" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="Ihr Name..." />
                   <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>
              <div class="w-full px-3 mb-3 md:mb-0 md:w-1/3">
                <label class="relative block">
                  <span class="text-gray-200">E-Mail</span>
                  <validation-provider name="E-Mail" rules="email|required" v-slot="{ errors }">
                    <input v-model="client.email" type="email" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="Ihre E-Mailadresse..." />
                   <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>
              <div class="w-full px-3 mb-3 md:mb-6 md:w-1/3">
                <label class="relative block">
                  <span class="text-gray-200">Telefon</span>
                  <validation-provider name="Telefon" rules="min:5|required" v-slot="{ errors }">
                    <input v-model="client.phone" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="Ihre Telefonummer" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>

              <div class="w-full px-3 mb-3 md:mb-6">
                <label class="relative block">
                  <span class="text-gray-200">Warum möchten Sie Ihre Immobilie verkaufen</span>
                  <textarea v-model="client.message" class="block w-full mt-1 bg-gray-700 form-textarea focus:text-gray-900 border-orange focus:bg-gray-500 focus:border-orange" rows="4" placeholder="..."></textarea>
                </label>
              </div>

              <div class="w-full px-3 text-center">
                <button class="text-gray-800 btn bg-orange hover:bg-yellow-600" type="submit" :disabled="invalid">
                  Absenden und Wert ermitteln
                </button>
                <span v-if="apiError" class="relative block w-48 px-2 py-2 mx-auto mt-2 text-sm leading-tight text-red-100 bg-red-600 rounded-sm shadow-lg">{{ errorMsg }}</span>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
// eslint-disable-next-line camelcase
import { length, email, min, required, alpha, alpha_spaces } from 'vee-validate/dist/rules';
import FormError from '../FormError.vue';
import de from 'vee-validate/dist/locale/de.json';
import { mapMutations, mapState } from 'vuex';

const API_URL = 'https://www.vonheide-immobilienbewertung.de/pricefromapi';

localize('de', de);
extend('length', length);
extend('email', email);
extend('min', min);
extend('required', required);
extend('alpha', alpha);
extend('alpha_spaces', alpha_spaces);

export default {
  name: 'StepFinal',
  components: {
    ValidationProvider,
    ValidationObserver,
    FormError,
    Loading
  },
  data: () => ({
    loader: {
      isLoading: false,
      fullPage: false,
      background: '#2B444F',
      opacity: 0.9,
      color: '#DEA814'
    },
    apiError: false,
    errorMsgDefault: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    errorMsg: null,
    client: {
      name: '',
      email: '',
      phone: '',
      message: ''
    }
  }),
  mounted () {
    if (this.apiData.client) {
      this.client = this.apiData.client;
    }
  },
  computed: {
    ...mapState(['apiData'])
  },
  methods: {
    ...mapMutations(['SET_STATE', 'NEXT']),
    async sendDataToApi () {
      this.errorMsg = null;
      this.loader.isLoading = true;
      this.apiError = false;
      try {
        const response = await axios({
          method: 'POST',
          url: API_URL,
          data: this.apiData
        });
        this.loader.isLoading = false;
        // console.log(response);
        if (response.data.error) {
          this.errorMsg = response.data.response;
          this.apiError = true;
        } else {
          this.NEXT();
        }
      } catch (err) {
        setTimeout(() => {
          this.errorMsg = this.errorMsgDefault;
          this.apiError = true;
          this.loader.isLoading = false;
        }, 600);
        // console.log(err);
      }
    },
    async onSubmit () {
      this.SET_STATE({ key: 'client', value: this.client });
      await this.sendDataToApi();
    }
  }
};
</script>
