<template>
  <div>
    <div>
      <h2 class="mb-3 h1">Adresse</h2>
      <p>
        Bitte geben Sie die Adresse Ihrer Immobilie ein.
      </p>
    </div>
    <div class="py-4 main md:py-8">
      <div class="flex flex-wrap justify-center space-x-6 select">
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(onSubmit)" class="text-left text-gray-100">
            <div class="flex flex-wrap justify-around -mx-3">
              <div class="w-3/4 px-3 mb-3 md:mb-0 md:w-1/4">
                <label class="relative block">
                  <span class="text-gray-200">Straße</span>
                  <validation-provider name="Straße" rules="min:3|alpha_spaces|required" v-slot="{ errors }">
                    <input v-model="address.street" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="Musterstraße" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>
              <div class="w-1/4 px-3 mb-3 md:mb-0 md:w-1/6">
                <label class="relative block">
                  <span class="text-gray-200">Hausnr.</span>
                  <validation-provider name="Hausnummer" rules="required" v-slot="{ errors }">
                    <input v-model="address.houseNo" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="10a" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>
              <div class="w-full px-3 mb-3 md:mb-0 md:w-1/4">
                <label class="relative block">
                  <span class="text-gray-200">PLZ</span>
                  <validation-provider name="PLZ" rules="digits:5|required" v-slot="{ errors }">
                    <input v-model="address.zip" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="55555" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>
              <div class="w-full px-3 mb-3 md:mb-6 md:w-1/4">
                <label class="relative block">
                  <span class="text-gray-200">Ort</span>
                  <validation-provider name="Ort" rules="alpha_spaces|required" v-slot="{ errors }">
                    <input v-model="address.city" type="text" class="block w-full mt-1 bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="Musterstadt" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </label>
              </div>
              <div class="w-full px-3 text-center">
                <button class="text-gray-800 btn bg-orange hover:bg-yellow-600" type="submit" :disabled="invalid" :title="[invalid ? 'Alle Felder müssen gültig sein.' : 'weiter']">
                  Weiter
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
// eslint-disable-next-line camelcase
import { length, email, min, required, digits, alpha_spaces } from 'vee-validate/dist/rules';
import FormError from '../FormError.vue';

import de from 'vee-validate/dist/locale/de.json';

localize('de', de);
extend('length', length);
extend('email', email);
extend('min', min);
extend('required', required);
extend('digits', digits);
extend('alpha_spaces', alpha_spaces);

export default {
  name: 'Step2',
  components: {
    ValidationProvider,
    ValidationObserver,
    FormError
  },
  data: () => ({
    address: {
      street: '',
      houseNo: '',
      zip: '',
      city: ''
    }
  }),
  mounted () {
    if (this.$store.state.apiData.address) {
      this.address = this.$store.state.apiData.address;
    }
  },
  methods: {
    ...mapMutations(['SET_STATE']),
    onSubmit () {
      this.SET_STATE({ key: 'address', value: this.address, goNext: true });
    }
  }
};
</script>
