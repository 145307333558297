import { tns } from 'tiny-slider/src/tiny-slider';
import './form.js';

// eslint-disable-next-line no-unused-vars
const slider = tns({
  container: '.tiny-slider',
  items: 1,
  controls: false,
  mouseDrag: true,
  navPosition: 'bottom'
});

const MQSmMax = window.matchMedia('(max-width: 768px)');
const leadGeneratorAnchor = document.querySelector('a[name=wert-ermitteln]');
if (MQSmMax.matches) {
  setTimeout(() => {
    leadGeneratorAnchor.scrollIntoView();
  }, 800);
}
