<template>
  <fragment>
    <div class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
      <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/20210128_vonheide_fußboden.svg`" />
      <label class="relative block w-full -mt-4 ">
        <span class="text-gray-200">Qualität des Fußboden</span>
          <select @change="$emit('change', { name: 'floorCovering', value: floorCovering } )" v-model="floorCovering" class="block w-full mt-1 bg-gray-700 form-select focus:text-gray-900 border-orange focus:bg-gray-500 focus:border-orange">
            <option v-for="option in options" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
      </label>
    </div>
    <div class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
      <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/20210128_vonheide_fenster.svg`" />
      <label class="relative block w-full -mt-4 ">
        <span class="text-gray-200">Qualität der Fenster</span>
          <select @change="$emit('change', { name: 'window', value: window } )" v-model="window" class="block w-full mt-1 bg-gray-700 form-select focus:text-gray-900 border-orange focus:bg-gray-500 focus:border-orange">
            <option v-for="option in options" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
      </label>
    </div>
    <div class="flex items-center w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
      <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/20210128_vonheide_sanitaer.svg`" />
      <label class="relative block w-full -mt-4 ">
        <span class="text-gray-200">Qualität der Sanitär-Ausstattung</span>
          <select @change="$emit('change', { name: 'sanitaryInstallation', value: sanitaryInstallation } )" v-model="sanitaryInstallation" class="block w-full mt-1 bg-gray-700 form-select focus:text-gray-900 border-orange focus:bg-gray-500 focus:border-orange">
            <option v-for="option in options" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
      </label>
    </div>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';
import { mapState, mapGetters } from 'vuex';
import Icon from './Icon.vue';

export default {
  components: {
    Fragment,
    Icon
  },
  data: () => ({
    floorCovering: 'GOOD',
    window: 'GOOD',
    sanitaryInstallation: 'GOOD',
    options: [
      { text: 'Luxus', value: 'GREAT' },
      { text: 'Gehoben', value: 'GOOD' },
      { text: 'Normal', value: 'AVERAGE' },
      { text: 'Einfach', value: 'POOR' }
    ]
  }),
  computed: {
    ...mapState(['apiData']),
    ...mapGetters(['fileadminPath'])
  }
};
</script>

<style>

</style>
