import Vue from 'vue/dist/vue.esm.js';
import Vuex from 'vuex';

import FormContainer from './components/FormContainer.vue';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    maxSteps: 5,
    currentStep: 0,
    isVilla: false,
    apiData: {
      realEstateType: null,
      rented: null,
      constructionYear: null,
      roomCount: null,
      floor: null,
      siteArea: null,
      exposure: null,
      cellar: null,
      address: {
        street: '',
        houseNo: '',
        zip: '',
        city: ''
      },
      parking: null,
      facilities: {
        wallCovering: 'GOOD',
        wallCoveringQuality: 'WELL_KEPT',
        roof: 'GOOD',
        roofQuality: 'WELL_KEPT',
        insulation: 'GOOD',
        insulationQuality: 'WELL_KEPT',
        floorCovering: 'GOOD',
        floorCoveringQuality: 'WELL_KEPT',
        window: 'GOOD',
        windowQuality: 'WELL_KEPT',
        heating: 'GOOD',
        heatingQuality: 'WELL_KEPT',
        sanitaryInstallation: 'GOOD',
        sanitaryInstallationQuality: 'WELL_KEPT'
      },
      client: {}
    }
  },
  getters: {
    fileadminPath: () => 'fileadmin/static'
  },
  mutations: {
    SET_STATE (state, { key, value, goNext }) {
      state.apiData[key] = value;
      if (goNext) state.currentStep++;
    },
    SET_VILLA (state, payload) {
      state.isVilla = payload;
    },
    SET_VILLA_FACILITY (state, { key, value }) {
      state.apiData.facilities[key] = value;
    },
    GO_TO_STEP: (state, step) => (state.currentStep = step),
    NEXT: state => state.currentStep++,
    PREV: state => state.currentStep--
  }
});

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  template: '<form-container></form-container>',
  el: '#app',
  store,
  components: {
    FormContainer
  }
});
