<template>
  <div class="flex flex-wrap items-start w-full md:flex-no-wrap">
    <div class="flex items-center flex-shrink-0 w-full px-3 mb-3 space-x-5 md:px-8 md:w-1/2">
      <icon class="flex-shrink-0 w-16 md:w-24" :src="`${fileadminPath}/assets/icons/20210128_vonheide_besondere_ausstattungsmerkmale.svg`" />
      <label class="relative block w-full -mt-4 ">
        <span class="text-gray-200">Besondere Ausstattungsmerkmale</span>
          <select @change="handleSelect" v-model="selectedSpecialFeature" class="block w-full mt-1 bg-gray-700 form-select focus:text-gray-900 border-orange focus:bg-gray-500 focus:border-orange">
            <option v-for="option in specialFeaturesOptions" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
      </label>
    </div>

    <div class="w-full px-3 mt-3 mb-3 md:px-8">
        <transition-group name="list" tag="div">
            <div class="mb-3" v-for="(specialFeature, idx) in specialFeaturesList" :key="specialFeature.optionValue">
              <span class="text-gray-200">{{specialFeature.text}}</span>
              <div class="relative flex items-start pr-8 space-x-2 md:pr-0 md:items-center">
                <div class="w-1/2 mt-1">
                  <validation-provider name="Preis" rules="numeric|required" v-slot="{ errors }">
                    <input required v-model="specialFeaturesList[idx][specialFeature.optionValue + 'Cost']" type="text" class="block w-full bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="Wert in EUR" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </div>
                <div class="w-1/2 mt-1">
                  <validation-provider name="Jahr" rules="numeric|digits:4|min_value:1500|required" v-slot="{ errors }">
                    <input required v-model="specialFeaturesList[idx][specialFeature.optionValue + 'Year']" type="text" class="block w-full bg-gray-700 focus:text-gray-900 form-input border-orange focus:bg-gray-500 focus:border-orange" placeholder="Baujahr z.B. 2010" />
                    <form-error v-if="errors.length > 0">{{ errors[0] }}</form-error>
                  </validation-provider>
                </div>
                <div class="absolute right-0 self-center cursor-pointer md:relative md:right-auto" @click="handleRemove(specialFeature)">
                  <svg class="w-6 h-6 text-orange" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd"></path></svg>
                </div>
              </div>
            </div>
        </transition-group>

    </div>

  </div>
</template>

<script>
import Icon from './Icon.vue';
import { mapState, mapGetters } from 'vuex';
import { ValidationProvider, extend, localize } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, numeric, digits, min_value } from 'vee-validate/dist/rules';
import FormError from './FormError.vue';

import de from 'vee-validate/dist/locale/de.json';

localize('de', de);
extend('required', required);
extend('numeric', numeric);
extend('digits', digits);
extend('min_value', min_value);

export default {
  components: {
    Icon,
    ValidationProvider,
    FormError
  },
  data: () => ({
    selectedSpecialFeature: '',
    specialFeaturesOptions: [
      { text: 'Einbauküche', value: 'builtInKitchen' },
      { text: 'Offener Kamin', value: 'chimney' },
      { text: 'Besondere Gartengestaltung', value: 'landscapeGardening' },
      { text: 'Solar- /Photovoltaikanlage', value: 'solarCollector' },
      { text: 'Gartenhäuschen', value: 'summerhouse' },
      { text: 'Swimmingpool', value: 'swimmingpool' },
      { text: 'Wintergarten', value: 'wintergarden' }
    ],
    specialFeaturesList: []
  }),
  watch: {
    specialFeaturesList: {
      deep: true,
      handler () {
        const specialFeaturesObj = {};
        this.specialFeaturesList.forEach((feature) => {
          // Only emit if all values are set
          if (typeof feature[feature.optionValue + 'Cost'] !== 'undefined' && typeof feature[feature.optionValue + 'Year'] !== 'undefined') {
            specialFeaturesObj[feature.optionValue + 'Cost'] = parseInt(feature[feature.optionValue + 'Cost']);
            specialFeaturesObj[feature.optionValue + 'Year'] = parseInt(feature[feature.optionValue + 'Year']);
            this.$emit('features-change', specialFeaturesObj);
          }
        });
      }
    }
  },
  methods: {
    handleSelect (e) {
      const featureAlreadyInList = this.specialFeaturesList.some((feature) => feature.optionValue === e.target.value);
      if (!featureAlreadyInList) {
        this.specialFeaturesList.push({
          text: e.target.options[e.target.selectedIndex].text,
          optionValue: e.target.value
        });
      }
      this.selectedSpecialFeature = '';
    },
    handleRemove (specialFeature) {
      const specialFeaturesList = this.specialFeaturesList.filter((feature) => feature.optionValue !== specialFeature.optionValue);
      this.specialFeaturesList = specialFeaturesList;
    }
  },
  computed: {
    ...mapState(['apiData']),
    ...mapGetters(['fileadminPath'])
  }
};
</script>

<style>

</style>
