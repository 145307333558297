var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.apiData.realEstateType === 'APARTMENT')?_c('div',[_c('h2',{staticClass:"mb-3 h1"},[_vm._v("Wohnungstyp")]),_vm._v(" "),_c('p',[_vm._v("\n      Bitte wählen Sie den Eigentumswohnungtyp aus.\n    ")])]):_c('div',[_c('h2',{staticClass:"mb-3 h1"},[_vm._v("Haustyp")]),_vm._v(" "),_c('p',[_vm._v("\n      Bitte wählen Sie Ihren Haustyp aus.\n    ")])]),_vm._v(" "),_c('div',{staticClass:"py-4 main md:py-8"},[(_vm.apiData.realEstateType === 'APARTMENT')?_c('div',{staticClass:"flex flex-wrap justify-center select"},[_c('div',{staticClass:"mx-3",on:{"click":function($event){return _vm.NEXT()}}},[_c('icon',{attrs:{"hover":"true","src":(_vm.fileadminPath + "/assets/icons/wohnung/20200629_vonheide_wohnung.svg"),"title":"Etagenwohnung"}})],1),_vm._v(" "),_c('div',{staticClass:"mx-3",on:{"click":function($event){return _vm.SET_STATE({
            key: 'floor',
            value: 'GROUND',
            goNext: true
          })}}},[_c('icon',{attrs:{"hover":"true","src":(_vm.fileadminPath + "/assets/icons/20200629_vonheide_eg.svg"),"title":"Erdgeschosswohnung"}})],1),_vm._v(" "),_c('div',{staticClass:"mx-3",on:{"click":function($event){return _vm.SET_STATE({
            key: 'penthouse',
            value: true,
            goNext: true
          })}}},[_c('icon',{attrs:{"hover":"true","src":(_vm.fileadminPath + "/assets/icons/20200629_vonheide_penthouse.svg"),"title":"Penthouse"}})],1),_vm._v(" "),_c('div',{staticClass:"mx-3",on:{"click":function($event){return _vm.SET_STATE({
            key: 'floor',
            value: 'BASEMENT',
            goNext: true
          })}}},[_c('icon',{attrs:{"hover":"true","src":(_vm.fileadminPath + "/assets/icons/20200629_vonheide_maisonette.svg"),"title":"Maisonette"}})],1)]):_c('div',{staticClass:"flex flex-wrap justify-center select"},[_c('div',{staticClass:"mx-3",on:{"click":function($event){return _vm.SET_STATE({
            key: 'realEstateType',
            value: 'SINGLE_FAMILY_HOUSE',
            goNext: true
          })}}},[_c('icon',{attrs:{"hover":"true","src":(_vm.fileadminPath + "/assets/icons/haus/20200629_vonheide_haus_einfamilienhaus.svg"),"title":"Einfamilienhaus"}})],1),_vm._v(" "),_c('div',{staticClass:"mx-3",on:{"click":function($event){return _vm.SET_STATE({
            key: 'realEstateType',
            value: 'SEMIDETACHED_HOUSE',
            goNext: true
          })}}},[_c('icon',{attrs:{"hover":"true","src":(_vm.fileadminPath + "/assets/icons/haus/20200629_vonheide_haus_doppelhaushaelfte.svg"),"title":"Doppelhaushälfte"}})],1),_vm._v(" "),_c('div',{staticClass:"mx-3",on:{"click":function($event){return _vm.SET_STATE({
            key: 'realEstateType',
            value: 'MID_TERRACE_HOUSE',
            goNext: true
          })}}},[_c('icon',{attrs:{"hover":"true","src":(_vm.fileadminPath + "/assets/icons/haus/20200629_vonheide_haus_reihenhaus.svg"),"title":"Reihenmittelhaus"}})],1),_vm._v(" "),_c('div',{staticClass:"mx-3",on:{"click":function($event){return _vm.SET_STATE({
            key: 'realEstateType',
            value: 'END_TERRACE_HOUSE',
            goNext: true
          })}}},[_c('icon',{attrs:{"hover":"true","src":(_vm.fileadminPath + "/assets/icons/haus/20200629_vonheide_haus_reihenhaus.svg"),"title":"Reihenendhaus"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }